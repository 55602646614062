import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  OutlinedInput,
  Drawer,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      color: 'green'
    },
    h5: {
      fontWeight: 600,
      color: "red"
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    body2: {
      fontSize: '0.7em',
    },
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        '&.passwordIcons': {
          width: "18px",
          height: "18px",
          color:'#94A3B8',
        }
      },
    },
    MuiInputBase: {
      input: {
        padding: '10.5px',
        "&::placeholder": {
          fontSize: "1em",
        },
        "&:focus": {

          borderRadius: '2px',
        },
      },

    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px',
        outline: "1px solid #CBD5E1",
        height: '3.6em',
        fontSize: '1em'
      },
      input: {
        height: '100%',
        padding: '0px 10.5px',
        fontSize: '1em'
      }
    },
    MuiDrawer: {
      root: {
        '&.noInternetAlert > .MuiPaper-root': {
          borderTopRightRadius: '25px',
          backgroundColor:'black'
        },

      }

    },
    MuiButton: {
      root: {
        fontSize: '1em',
        textTransform: 'none',
        borderRadius: '8px',
        margin: '12 0 0',
        '&.proceedBtn': {
          color:'#1E293B',
          background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
          padding: '12',
          fontWeight: '700',
          marginTop: '32px'
        },
      }
    }
  },
});
import {CheckCircle, Close } from "@material-ui/icons";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { app_logo} from "../../email-account-login/src/assets";
import { imagea, imageb, passwordUpdateSuccess} from "./assets";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start


    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <div style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',position:'fixed',backgroundRepeat: 'no-repeat',backgroundSize: '100% 100%',width:'100vw'}}>
        <Container maxWidth={"sm"} style={{ height: '100vh',overflow:'scroll' }}>
        <img src={imagea} alt="imagea" style={{position:'absolute',top:"0px",right:"-25px"}}></img>
          <img src={imageb} alt="imageb" style={{position:'absolute',bottom:0,left:0}}></img>
          <Box sx={webStyle.mainWrapper}>
            <img src={app_logo} alt="app_logo" style={webStyle.logoe}/>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%',marginTop:'20px'}}>
              {this.state.errorMsg &&
                <Box sx={webStyle.alertBox} className="alert-box">
                  {this.state.errorMsg}
                </Box>
              }
              <Box sx={{ width: "100%", minHeight: "60px" }}>
                <Box sx={{ fontSize: '1.1em', color: '#FFFFFF', fontWeight: '700', margin: '16 0 8' }}>New password</Box>
                <Box sx={{ fontSize: '1em', color: '#FFFFFF', fontWeight: '400', margin: '0 0 8' }}>Enter a new password for {this.state.emailValue}</Box>

              </Box>
              <form style={webStyle.form}>
                <InputLabel style={{ color:'#E2E8F0',fontSize: '1em', fontWeight: '700', margin: '18 0 8' }}>
                  New Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  name="newPassword"
                  value={this.state.newPassword}
                  type={this.state.showNewPass ? "password" : 'text'}
                  id="newPassword"
                  placeholder="Your new password"
                  style={{ margin: '0px 0px 5px', color: '#CBD5E1',background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}
                  data-test-id='newPasswordInput'
                  onFocus={() => this.validatePassword('focus')}
                  onBlur={() => this.validatePassword('blur')}
                  onChange={(event) => this.setNewPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.setShowPassword('new')}
                        edge="end"
                      >
                        {!this.state.showNewPass ? (
                          <Visibility className="passwordIcons" />
                        ) : (
                          <VisibilityOff className="passwordIcons" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }

                />
                <InputLabel style={{ color:'#E2E8F0',fontSize: '1em', fontWeight: '700', margin: '24 0 8' }}>
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  type={this.state.showConfirmPass ? "password" : 'text'}
                  id="confirmPassword"
                  placeholder="Confirm your new password"

                  style={{ margin: '0px 0px 5px', color: '#CBD5E1',background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)' }}
                  data-test-id='confirmPasswordInput'
                  onChange={(event) => this.setConfirmPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.setShowPassword('confirm')}
                        edge="end"
                      >
                        {!this.state.showConfirmPass ? (
                          <Visibility className="passwordIcons" />
                        ) : (
                          <VisibilityOff className="passwordIcons" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }

                />
                {this.state.showPasswordValidation && <Grid style={{ margin: '12px 0px' }} item>
                  <Box sx={{ width: "100%", minHeight: "60px" }}>
                    <Box sx={{ fontSize: '1em', color: '#FFFFFF', fontWeight: '400', margin: '16 0 8' }}>Your password must contain</Box>
                    {this.state.passwordErrors.map((item: any, index: number) => {
                      return (
                        <Box
                          sx={{
                            marginTop: "5px",
                            display: "flex",

                            flexWrap: "no-wrap",
                            flexDirection: "row",
                            alignItems: "center",

                            fontFamily: `Inter`,

                          }}
                          key={index}>
                          <Box sx={{ marginRight: "5px" }}>
                            {item.isValid ?
                              <CheckCircle fontSize="small" style={{ color: '#34D399', }} /> : <RemoveCircleIcon fontSize="small" style={{ color: "#DC2626" }} />}
                          </Box>
                          <Box sx={{ color: '#FFFFFF', fontSize: '0.9em' }}>{item.msg}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>}

                <Button
                  data-test-id="submitBtn"
                  type="submit"
                  className="proceedBtn"
                  variant="contained"
                  style={webStyle.submit}
                  fullWidth
                  onClick={this.sendPasswordChangeRequest}
                >
                  Set New Password
                </Button>
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'baseline' }}>
                  <span style={{ fontSize: '1em', color: '#E2E8F0' }}>Back to</span>
                  <Button type="button" data-test-id="linkToNavigate" onClick={this.naviagteToLogIn} variant="text" style={{ color: '#F8FAFC', background:'none',fontSize: '1em', fontWeight: 700, marginLeft: 3 }}>
                    Log in
                  </Button>
                </div>
              </form>
            </Box>
          </Box>
          <Drawer data-test-id="password-update-alert" className="noInternetAlert" anchor={'bottom'} open={this.state.passwordUpdated} onClose={() => this.closeSuccesAlert()} >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="text" data-test-id="closeBtn" style={{ margin: '20px 3px', padding: '0px' }} onClick={() => this.closeSuccesAlert()}><Close style={{ fontSize: '2em' }} /></Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 24px 24px' }}>
              <div style={{ width: 56, height: 56, backgroundColor: '#D1FAE5', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '200px' }}>
                <img src={passwordUpdateSuccess} alt="No Internet" />
              </div>
              <Typography variant="subtitle1" component="div" style={{ fontSize: '1.6em', fontWeight: 700, margin: '12 0 0', color: '#FFFFFF' }}>
                Successfully <br />Changed !
              </Typography>
              <Typography variant="subtitle1" component="div" style={{ fontSize: '0.96em', fontWeight: 400, margin: '8 0', color: '#FFFFFF' }}>
                Your new password has been successfully updated.
              </Typography>
              <Button
                data-test-id="proceedBtn"
                className="proceedBtn"
                fullWidth
                type="button"
                variant="contained"
                onClick={this.proceedFurther}
              >
                Proceed
              </Button>
            </Box>
          </Drawer>
        </Container>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  logoe: {
    filter: 'invert(1)',
    marginTop: "80px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    paddingBottom: "30px",
    height: '80%',
    alignItems: 'center'
  },
  form: {
    marginTop: theme.spacing(1),

    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 5),
    background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
    color:'#1E293B',
  },
  alertBox: {
    backgroundColor: '#FEE2E2',
    padding: '8px 12px',

    borderLeft: '4px solid red',
    borderRadius: '4px',
    fontSize: '0.9em',

    margin: '12px 0',
    width: '90%',
    color: 'red',
  }
};
// Customizable Area End
