import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { imgVisbility, imgVisbilityOff } from "./assets";

// Customizable Area End

import Cfonplatformscreencreation0Controller, {
  Props,
  configJSON,
} from "./Cfonplatformscreencreation0Controller";
import { Box, CircularProgress } from "@material-ui/core";

export default class Cfonplatformscreencreation0 extends Cfonplatformscreencreation0Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div style={{width:"100%",height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <div className={this.state.assetType.includes("image") ? `${this.state.fade ? "fade-in" : "fade-out"}` : ""} style={{
              display: 'block',
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              aspectRatio:'1/1',
              maxWidth:'100vw',
              height: "100%",
              overflow:'hidden',
              animationName: "fade", animationDuration: "1.5s",
          }}>
              {this.state.loader && <div style={styles.circularProgressDiv}>
                <CircularProgress style={{color:'grey'}}/>
              </div>}
              {this.state.qrCode ? <img data-test-id="qr-block" src={this.state.qrCode} style={{ objectFit:"contain",display:'block', width:'100%',height:'100%'}} alt="ads"/> : ""}
              {this.state.assetType.includes("image") ? <img data-test-id="img-block" src={this.state.screenAssetsData[0]} style={{ objectFit:"contain", display:'block', width:'100%',height:'100%' }} alt="ads"/> : ""}
              {this.state.assetType.includes("video") && this.state.screenAssetsData.length > 1 ? 
                  <video data-test-id="multi-video-block" id="myVideo" muted={this.state.muted}  src={this.state.screenAssetsData[this.state.current]} style={{ objectFit:"contain", display:'block', width:'100%',height:'100%' }} autoPlay onEnded={this.handleVideoEnd}></video>
                : 
              ""} 
              {this.state.assetType.includes("video") ? 
                <video 
                  muted={this.state.muted} 
                  src={this.state.screenAssetsData[0]} 
                  data-test-id="video-block" 
                  id="myVideo" 
                  style={{ objectFit:"contain", display:'block', width:'100%',height:'100%', pointerEvents: "none" }} 
                  autoPlay 
                  loop
                ></video> 
              : ""}
              
              {this.state.error && <Box position={"fixed"} style={styles.errorWrapper}>
                <div data-test-id="error-block" style={{ ...styles.errorText, padding: "5px 15px"}}>{this.state.error}</div>
              </Box>}
          </div> 
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
  circularProgressDiv:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:'100%'
  },
  errorWrapper: {
    bottom: "20px",
    color: "red",
    left: "0",
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  errorText: {
    backgroundColor: "rgb(254, 226, 226)",
    borderRadius: 5,
  }
});


// Customizable Area End
