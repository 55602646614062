import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';

import { getStorageData } from "framework/src/Utilities";
import {AppBar, Toolbar, Box, Button, Typography, styled, InputAdornment, TextField, IconButton, Popover, Divider, MenuItem,
	ListItemIcon,
	ListItemText} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { MdDeleteOutline, MdMoreHoriz, MdClear, MdEdit, MdOutlineDvr } from "react-icons/md";
import { FaRegCalendarCheck } from 'react-icons/fa';
import HamburgerMenu from "../../../blocks/hamburgermenu/src/HamburgerMenu.web";
import ScreenTable from '../../../components/src/commonTable/ScreenList';
import ConfirmationDrawer from '../ConfirmationDrawer';

export const success_icon = require("../../assets/success-icon.png");

const configJSON = require("../config");
const moreMenu = [
  {
		title: "Edit Screen",
		key: 'Edit_screen',
		icon: <MdEdit />,
	},
	{
		title: "View Screen",
		key: 'View_screen',
		icon: <FaRegCalendarCheck />,
	},
	{
		title: "Ad Details",
		key: 'Ad_details',
		icon: <MdOutlineDvr />,
	},
]
type recentScreenListType =  {[key:string]: string}
type RegionListDataType = {
  id: number; 
  name: string;
}
export interface Props {
  navigation: any;
}

interface S {
  
  anchorEl: null | HTMLElement;
  headerAnchorEl: null | HTMLElement;
  regionsList: Array<RegionListDataType>;
  screenList: object[];
  recentScreenList: screenListType[];
  regionSearch: string;
  screenSearch: string;
	selectedRegion: string;
  regionPopup: boolean;
  screenLoader: boolean;
  filesLoader: boolean;
  fileDetailData: object;
  screenDetail: boolean;
  openDescModal: boolean;
  displayAlert: boolean;
  removeData: boolean;
  removeAdLoader: boolean;
}

interface SS {
  navigation: any;
}
type screenListType =  {[key:string]: string}
interface ScreenFile {
  id: string;
  type: string;
  attributes: any;
}
export default class LocalListingAds extends BlockComponent<Props, S, SS> {

  regionsListApiID: string = '';
  recentListApiID: string = '';
	regionScreensApiID: string = '';
	clearSearchDataApiID: string = '';

	recentScreensApiID: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

		this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),

    ];

		this.state = {
      anchorEl: null,
      headerAnchorEl: null,
      regionsList: [],
      screenList: [],
      recentScreenList: [],
      regionSearch: '',
      screenSearch: '',
      selectedRegion: '',
      regionPopup: false,
      screenLoader: false,
      filesLoader: false,
      fileDetailData: {},
      screenDetail: false,
      openDescModal: false,
      displayAlert: false,
      removeData: false,
      removeAdLoader: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

	async componentDidMount() {
			this.getRegionsList();
			this.getRecentScreensList();
	}

	getRegionsList = async() => {
    this.regionsListApiID = await this.apiCall({
      method: configJSON.httpGetMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.regionsListEndPoint
    })
  }

	clearSearchHistory = async() => {
    this.clearSearchDataApiID = await this.apiCall({
      method: configJSON.httpDeleteMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.clearSearchHistory
    })
  }

	handleSearch= async(screen: string, region: string) => {
		this.setState({screenLoader: true, screenList: []});
    this.regionScreensApiID = await this.apiCall({
      method: configJSON.httpGetMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: this.getScreenAPIUrl({screen, region})
    })
	}

	getRecentScreensList = async() => {
    this.recentScreensApiID = await this.apiCall({
      method: configJSON.httpGetMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.recentScreensEndPoint
    })
  }

	async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      runEngine.debugLog("API Message Recived", message);
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if(responseJson){
        this.handleSuccess(responseJson, apiRequestCallId)
      }
    }
  }

	handleSuccess = (
    responseJson:
      {regions:Array<RegionListDataType>} & {file: {data: {}}} & {recent_search: screenListType[]} &
      {[key:string]: Array<{[key:string]: {[key:string]: string | number | null}}>} & {data: ScreenFile[]}, 
    apiRequestCallId:string
  ) => {

    switch(apiRequestCallId) {
      case this.regionsListApiID:
        this.setState({
          regionsList: responseJson.regions || []
        });
        break;
      case this.regionScreensApiID :{
          let fetchedData = responseJson.data || []
          this.setState({
            screenList: fetchedData,
            screenLoader: false
          });
        }
        break;
      case this.recentScreensApiID:
        this.setState({
          recentScreenList: responseJson.recent_search || []
        });
        break;
      case this.clearSearchDataApiID:
          this.setState({
            removeData: false, 
            removeAdLoader: false,
            displayAlert: true
          });
          setTimeout(() => {
            this.setState({ displayAlert: false });
          }, 2000);
          break;
      default:
        break;
    }
    
  }

	apiCall = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
    bodyData?: {[key: string]: string | number}
  }) => {
    const { contentType, method, endPoint, bodyData } = data;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    bodyData && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

	clearSearch = () => {
    this.setState({ screenSearch: '' });
  };
	handleHeaderMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		this.setState({
      headerAnchorEl: event.currentTarget
    })
	};

	handleHeaderClose = () => {
		this.setState({ headerAnchorEl: null })
	};
	handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

	handleMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  };
	handleMenuItemClick = () => {
		this.clearSearchHistory();
		this.handleHeaderClose();

	}
	getMoreMenuData = () => {
		return [
			{
				title: "Clear History",
				key: 'clear_history',
				icon: <MdDeleteOutline />,
			}]
	}

  handleRegionSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      regionSearch: event.target.value
    })
  }

	selectRegion = (value: string) => {
    this.setState({selectedRegion: value})
  }

	getScreenAPIUrl = ({screen, region}: {screen?: string, region?: string}) => {
    const endPoint = configJSON.regionScreensEndPoint;
    if(screen && region) return `${endPoint}screen=${screen}&region=${region}`;
    if(region && !screen) return `${endPoint}region=${region}`;
    if(screen && !region) return `${endPoint}screen=${screen}`;
    else return endPoint;
  }

	handleSearchScreen = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({screenSearch: event.target.value})
  }

  handleRemoveData = (key?: string) => {
    this.setState({removeData: !this.state.removeData})
  }

  renderHeader = () => {
    return (
      <StyledHeader>
        <Box />
        <Typography className="pageTitle"> SEARCH </Typography>
				<IconButton
					aria-haspopup="true"
					style={{padding: 0, margin: 0}}
					onClick={(event) => this.handleHeaderMenuClick(event)}
				>
        	<MdMoreHoriz style={{color: '#fff', width: '24px'}} data-test-id="searchIcon" />
				</IconButton>

				<StyledMenu
					id="customized-menu"
					anchorEl={this.state.headerAnchorEl}
					keepMounted
					open={Boolean(this.state.headerAnchorEl)}
					onClose={this.handleHeaderClose}
				>
					{this.getMoreMenuData().map((menu: any) => (
						<StyledMenuItem key={menu.key} onClick={() => this.handleRemoveData()}>
							<ListItemIcon style={{minWidth: "35px"}}>
								{menu.icon}
							</ListItemIcon>
							<ListItemText style={{fontSize: '14px'}} primary={menu.title} />
						</StyledMenuItem>
					))}
				</StyledMenu>
      </StyledHeader>
    );
  };

  renderTopActions = () => {
    return (
      <>
        <TopActions>
					<StyledTextField
						data-test-id="search_screens"
						variant="outlined"
						value={this.state.screenSearch}
						placeholder={"Search for screens, users etc"}
						InputProps={{
							startAdornment: <InputAdornment position="start"><SearchIcon className="searchIcon" /></InputAdornment>,
							endAdornment: (
								<InputAdornment position="end">
									{this.state.screenSearch && (
										<MdClear data-test-id="clear_input" className="clearIcon" onClick={this.clearSearch} />
									)}
								</InputAdornment>
							),
						}}
						onChange={this.handleSearchScreen}
					/>

          <MenuClick data-test-id="region-menu" onClick={this.handleMenuOpen}>
            <Typography className="menuSelectTxt">{ this.state.selectedRegion || "Select region"}</Typography>
            <KeyboardArrowDownIcon />
          </MenuClick>
          
          <StyledPopover
            data-test-id="popover-data"
            elevation={3}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
          >
            <Box className="formControl">
              <StyledTextField
                variant="outlined"
                placeholder={configJSON.headerSearchTxt}
                value={this.state.regionSearch}
                onChange={this.handleRegionSearch}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon className="searchIcon" /></InputAdornment>,
                }}
                />
            </Box>
						<ul className="regionsList">
							{
								this.state.regionsList.filter((region: any) => region.name.toLocaleLowerCase().includes(this.state.regionSearch.toLocaleLowerCase())).map((region: any) => {
									return (
										<li data-test-id="region_item" key={region.id} className="regionsListItem" onClick={() => {
											this.handleMenuClose();
											this.selectRegion(region.name)
										}}>
											{region.name}
										</li>
									)
								})
							}
						</ul>
          </StyledPopover>

					<GoBackToScreenButton fullWidth onClick={() => this.handleSearch(this.state.screenSearch, this.state.selectedRegion)}>
						Search
					</GoBackToScreenButton>
        </TopActions>
      </>
    );
  };

  renderRecentSearch = () => {
    return (
      <>
        <RecentSearch>
          <Typography className="rsTitle">{
						this.state.screenList?.length ? "SEARCH Results" : "Recent searches"
					}</Typography>
          <CustomDivider variant="middle" />

					{this.state.screenList?.length ? (
						<ScreenTable 
							data-test-id="table"
							headerKeys={configJSON.screenListHeaderKeys}
							tableData={this.state.screenList}
							showFilter={false}
							moreMenuList={moreMenu}
							isLoading={this.state.screenLoader}
							navigation={this.props.navigation}
						/>
					) : (
						<div className="recentSearch">
							{
								this.state.recentScreenList.map((screen: recentScreenListType, index: number)=> (
									<ScreenListItem 
										key={`${screen.region_name}-${index}`} 
										data-test-id={`${screen.region_name}-${index}`}
										onClick={() => this.handleSearch(screen.screen_name, screen.region_name)}
									>
										<Typography className="screenName" >{screen.screen_name}</Typography>
										<Typography className="region">{screen.region_name}</Typography>
										<ChevronRightIcon className="icon"/>
									</ScreenListItem>
								))
							}
						</div>
					)}
        </RecentSearch>
      </>
    );
  };

  render() {
    return (
      <HamburgerMenu 
        navigation={this.props.navigation} 
        fromPage="search" 
      >
        <Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
          <Toolbar>
            {this.renderHeader()}
          </Toolbar>
          <MainContent>
            {this.renderTopActions()}
            {this.renderRecentSearch()}
          </MainContent>
        </Box>
        <ConfirmationDrawer 
          navigation={this.props.navigation} 
          open={this.state.removeData}
          handleOpen={this.handleRemoveData}
          handleCancel={this.handleRemoveData}
          handleSuccess={this.handleMenuItemClick}
          title={"Are you sure you want to clear search history?"}
          activeButtonText={"Confirm"}
          activeButtonLoader={this.state.removeAdLoader}
        />
        {this.state.displayAlert ? <Box style={{...styles.successStatusWrapper, position: 'sticky'}} data-test-id="card_success">
          <Box style={styles.successStatus}>
            <img src={success_icon} style={{height: '25px', width: '25px'}}/> {'Search history has been deleted'}
          </Box>
        </Box> : null}
      </HamburgerMenu>
    );
  }
}

const styles = {
  successStatusWrapper: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    margin: 'auto',
    bottom: 20,
    zIndex: 9999,
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "0 auto",
    color: "#0F172A",
    fontSize: "14px",
    lineHeight: "24px",
    width: 'calc(100vw - 80px)',
    borderRadius: "8px",
    backgroundColor: "#FFF",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center',
    bottom: 40,
  },
}
const StyledHeader = styled(AppBar)({
  width: '100%',
  display: "flex",
  flexDirection: 'row',
  justifyContent: "space-between",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  boxShadow: "none",
  alignItems: "centner",
  padding: "20px 16px 16px 20px",
  color: "#FFF",
  "& .pageTitle":{
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  borderBottom: "1px solid #64748B",
});

const CustomDivider = styled(Divider)({
  backgroundColor: "#94A3B8",
  height: '1px',
})

const MainContent = styled(Box)({
  background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
  minHeight: "80vh",
});

const TopActions = styled(Box)({
  padding: "16px 16px 30px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "12px"
})


const StyledTextField = styled(TextField)({
  background: "#01010199",
  borderRadius: "8px",
  width: "100%",
  
  "& .MuiOutlinedInput-root":{
    height: "48px",
  },
  "& .MuiOutlinedInput-input":{
    padding: "0",
    color: "#94A3B8",
  },
  "&.MuiOutlinedInput-notchedOutline":{
    border: "none"
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "transparent"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
    borderWidth: "0px"
  },
  "& .MuiOutlinedInput-input::placeholder":{
    color: "#94A3B8",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
		opacity: 1
  },
  "& .searchIcon":{
    color: "#94A3B8"
  },
  "& .clearIcon":{
    color: "#94A3B8",
    cursor: "pointer"
  },
  "& MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart": {
    border: "1px solid Green",
    borderImageSource: "linear-gradient(175.74deg, #FFFFFF 3.31%, #999999 96.39%)",
  },

});

const MenuClick = styled(Box)({
  background: "#01010199",
  padding: "4px 8px 4px 12px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  height: "48px",
  boxSizing: "border-box",
  color: "#94A3B8",
  "& .MuiSvgIcon-root":{
    fontSize: "16px"
  }
});

const RecentSearch = styled(Box)({
  "& .rsTitle":{
    textTransform: "uppercase",
    paddingLeft: "16px",
    color: "#94A3B8",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    marginBottom: "16px",
  },
  "& .recentSearch": {
		maxHeight: '800px',
    overflow: 'scroll',
  }
})


const ScreenListItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #334155",
  // backgroundColor: "#01010180",
  color: "#94A3B8",
  paddingLeft: "16px",
  gap: "5px",
  height: "50px",
  "& .screenName":{
    flex: "0.3",
    fontSize: "14px",
  },
  "& .region":{
    flex: "0.5",
    fontSize: "12px",
  },
  "& .icon":{
    flex: "0.1"
  },
})

const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper":{
    maxHeight: "calc(100% - 220px)",
    width: "100%",
  },
  "& .formControl":{
    padding: "12px 16px",
    background: "#484848",
    "& .MuiTextField-root":{
      borderRadius: "24px"
    },
    "& .MuiOutlinedInput-root":{
      height: "40px",
      borderRadius: "24px",
      "&:hover .MuiOutlinedInput-notchedOutline":{
        borderColor: "#94A3B8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#94A3B8"
      },
      
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderWidth: "1px"
    },
  },
  "& .regionsList":{
    padding: 0,
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    marginTop: 0,
    marginBottom: 0,
    background: "#1B1B1BCC",
  },
  "& .regionsListItem":{
    height: "48px",
    padding: "12px 16px",
    background: "#1B1B1BCC",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFF",
    boxSizing: "border-box"
  },
  "& .regionsListItem:hover":{
    background: "#010101CC",
    color: "#FFF",
  }
})

const GoBackToScreenButton = styled(Button)({
  padding: '12px 0px',
  borderRadius: '8px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'none',
})

const StyledMenu = withStyles({
  paper: {
    background: "#000",
    color: "#94A3B8",
    borderRadius: "8px"
  },
	list: {
		padding: 0
	},
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
		// style={{}}
    {...props}
  />
));

const StyledMenuItem = styled(MenuItem)({
  color: '#94A3B8',
  minWidth: '180px',
  '& svg': {
    color: '#94A3B8',
    height: '25px',
    width: '25px',
  },
});