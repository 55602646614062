import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { GenericApiCall, checkTokenError } from "../../utilities/src/GenericApiCall";
import { getToken } from "../../utilities/src/RegExRequired";
import moment from "moment";
import { get } from '../../../components/src/commonTable/table';
import React from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  uploadedFiles: any[] ;
  uploadingFile:string | any;
  uploadProgress: number;
  anchorEl: HTMLElement | null;
  selectedFileIndex: number | null;
  fileName:any;
  fileSize:string;
  duration:string;
  errorMsg:{
    type:"error"|"warning"|"info"|"success"|"",
    msg:string
  },  itemToDelete:number;
  screenSelected:any;
  allScreens:any;
  showErrMsg: boolean;
  mediaErr:string;
  progress:any[],
  status:any[],
  failedFiles:any[],
  screenBookings: any;
  bookingDatesOriginal: any;
  startDate:any;
  endDate: any;
  isPaymentDrawer: boolean;
  savedCardList: [];
  errMessage: string;
  anchorEl2: any;
  amount: number;
  recentFiles: any;
  recentFilesAll: any;
  openScannedModal: boolean;
  openDescModal:  boolean;
  thumbnail: any;
  durationValid: boolean;
  pixelValid: boolean;
  file: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  preview?: string;
  // Customizable Area End
}

export default class Uploadmedia3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScreensApiCallId = '';
  getScreenFilesApiCallId = '';
  getSavedCardListId = '';
  getScreenBooklingsApiCallId = '';
  postCreationApiCallId = '';
  uploadMediaApiCallId = '';
  errorMsgInterval: any;
  canvasRef: any;
  uploadRef: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.canvasRef = React.createRef();
    this.uploadRef = React.createRef();

    this.state = {
      file: '',
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      uploadedFiles: [],
    uploadingFile: null,
    uploadProgress: 10,
    anchorEl: null,
    selectedFileIndex: null,
    fileName: null,
    fileSize: "",
    duration: "",
    errorMsg:{
      type:'',
      msg:''
    },    itemToDelete:0,
    screenSelected: "",
    allScreens:[],
    showErrMsg: false,
    mediaErr:'',
    status:[],
    progress:[],
    failedFiles:[],
    screenBookings: [],
    bookingDatesOriginal: [],
    startDate: "",
    endDate: "",
    isPaymentDrawer: false,
    savedCardList: [],
    errMessage: "",
    anchorEl2: null,
    amount: 0,
    recentFiles: "",
    recentFilesAll: [],
    openScannedModal: false,
    openDescModal: false,
    thumbnail: "",
    durationValid: false,
    pixelValid: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(getName(MessageEnum.NavigationPayLoadMessage) == message.id){
      let data = message.getData("file");
      this.setState({file: data});
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );    

    if (apiRequestCallId != null) {
      if(apiRequestCallId === this.getScreensApiCallId){
        this.handleScreensApiresponse(responseJson)
      }
      else if(apiRequestCallId === this.getScreenBooklingsApiCallId){
        this.handleScreenBookingApiresponse(responseJson)
      }
      else if(apiRequestCallId === this.getSavedCardListId) {
        this.handleSavedCardApiResponse(responseJson);
      }
      else if (apiRequestCallId === this.getScreenFilesApiCallId){
        this.handleScreenFilesApiResponse(responseJson);
      }
      else if (apiRequestCallId === this.postCreationApiCallId){
        if(responseJson?.message === "Media uploaded successfully!"){
          this.handlePaymentDrawer()
          this.navigateToOtherScreen('PaymentSuccess');
        }
        localStorage.removeItem('uploadImage')
        localStorage.removeItem('PaymentScreen')
        localStorage.removeItem('scannedQrValue');
        localStorage.removeItem('selectedScreen');
        localStorage.removeItem('selectedActiveScreen');
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  handleUploadClick = () => {
    this.uploadRef.current.click();
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  handleOpenDescModal = () => {
    this.setState({ openDescModal: true });
  }

  handleDescModalClose = () => {
    this.setState({ openDescModal: false });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleScreensApiresponse(responseJson:any){
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    } else {
      // const excludeDates = this.handleExcludeDates(responseJson.data[0]);
      let scannedQrValue:any = localStorage.getItem('scannedQrValue');
      const selectedScreen = localStorage.getItem('selectedScreen');
      const selectedActiveScreen = localStorage.getItem('selectedActiveScreen');
      const getAvailableScreens = responseJson?.data?.filter((item: any) => item.attributes.status !== "unavailable");
      const navigatedFromUrlScreen = this.props.navigation.getParam('screenid');

      if(navigatedFromUrlScreen){
        const getScanned = responseJson.data.filter((item: any) => item.id === navigatedFromUrlScreen);
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === getScanned[0].attributes.media_format.toLowerCase());
        this.getScreensBookings(getScanned[0]);  
        this.setState({ allScreens: getAvailableScreens, screenSelected: getScanned[0], openScannedModal: true, amount: getScanned[0].attributes.price, recentFiles: recentFiles.splice(0, 3) })
      } else if(selectedActiveScreen){
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === JSON.parse(selectedActiveScreen).attributes.media_format.toLowerCase());
        this.getScreensBookings(JSON.parse(selectedActiveScreen));
        this.setState({ allScreens: getAvailableScreens, screenSelected: JSON.parse(selectedActiveScreen), amount: JSON.parse(selectedActiveScreen).attributes.price, recentFiles: recentFiles.splice(0, 3) });
      } else if(scannedQrValue){
        const getScanned = responseJson.data.filter((item: any) => item.id === scannedQrValue);
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === getScanned[0].attributes.media_format.toLowerCase());
        this.getScreensBookings(getScanned[0]);  
        this.setState({ allScreens: getAvailableScreens, screenSelected: getScanned[0], openScannedModal: true, amount: getScanned[0].attributes.price, recentFiles: recentFiles.splice(0, 3) })
      } else if(selectedScreen){
        const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes.media_format.toLowerCase() === JSON.parse(selectedScreen).attributes.media_format.toLowerCase());
        this.getScreensBookings(JSON.parse(selectedScreen));        
        this.setState({ allScreens: getAvailableScreens, screenSelected: JSON.parse(selectedScreen), amount: JSON.parse(selectedScreen).attributes.price, recentFiles: recentFiles.splice(0, 3) });
      } else {
        this.getScreensBookings(getAvailableScreens[0]);
        this.setState({ allScreens: getAvailableScreens });
      }
    }
  }

  getFieldValue = (keyValue: string): string => {
    return get(this.state.screenSelected, `attributes.${keyValue}`)
  }

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleScreenBookingApiresponse(responseJson:any){
    if(responseJson.errors){
      this.handleErrorResponse(responseJson)
    }
    else{
      const { dateArray, originalDateArray } = this.handleExcludeDates(responseJson.dates);
      this.setState({ screenBookings: dateArray, bookingDatesOriginal: originalDateArray })
    }
  };

  handleSavedCardApiResponse(responseJson:any){
    const excludeDates = responseJson?.data;
    this.setState({ savedCardList: excludeDates });
  };

  handleScreenFilesApiResponse(responseJson:any){
    const recentFiles = responseJson?.data?.filter((file: any) => file.attributes.status !== "rejected");
    
    this.setState({ recentFilesAll: recentFiles });
  };  

  handleExcludeDates(dates: any){
    if(dates){
      var dateArray: any = [];
      let originalDateArray: any = [];
      dates.map((date: any) => {
        const start = new Date(date.start_date);
        const end = new Date(date.end_date);

        while (start <= end) {
          dateArray.push(new Date(start));
          originalDateArray.push(moment(new Date(start)).format("YYYY-MM-DD"))
          
          start.setDate(start.getDate() + 1);
        }
      })

      return { dateArray, originalDateArray }
    } else {
      return { dateArray: [], originalDateArray: [] };
    }
  }

  isBookedExist = (start: any, end: any, dateArray: any) => {
    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      // Format the date to a string
      const dateStr = d.toISOString().split('T')[0];
      
      // Check if the date is in the array
      if (dateArray.includes(dateStr)) {
        return { isBooked: true, date: dateStr };
      }
    }
    return { isBooked: false };
  }

  
  handleDateChange(date: string, value: any){
    if(date === "startDate"){
      this.setState({ ...this.state, startDate: value, endDate: '', amount: this.state.screenSelected.attributes.price });
    } else {
      const { startDate } = this.state;
      const result = this.isBookedExist(new Date(moment(startDate).format("YYYY-MM-DD")), value, this.state.bookingDatesOriginal);
      
      if(result.isBooked) {
        this.setState({ ...this.state, showErrMsg: true, endDate: "", errMessage: `${result.date} is booked, please select different dates` });
        setTimeout(() => {
          this.setState({ ...this.state, showErrMsg: false, errMessage: '' });
        }, 3000);
      } else {
        const data = this.handleExcludeDates([
          { 
            start_date: moment(new Date(this.state.startDate)).format("YYYY-MM-DD"),
            end_date: moment(value).format("YYYY-MM-DD") 
          }
        ])
        
        this.setState({ endDate: value, amount: this.state.screenSelected.attributes.price * data.originalDateArray.length });
      }
    }
  }

  handleErrorResponse = (responseJson: any) => {
    let errorMsg = checkTokenError(responseJson)
    if (errorMsg.type == 'token') {
      this.setState({
        errorMsg: { type: 'error', msg: errorMsg.msg },
        showErrMsg: true
      })
    }
    else {
      let msg: string[] = Object.values(responseJson.errors[0]);
      this.setState({
        errorMsg: { type: 'error', msg: msg[0] },
        showErrMsg: true
      });
    }

    // Clear previous interval if it exists
    if (this.errorMsgInterval) {
      clearInterval(this.errorMsgInterval);
    }
    // Set new interval to hide error message
    this.errorMsgInterval = setTimeout(() => {
      this.setState({ showErrMsg: false });
      if(errorMsg.type == 'token'){
        this.tokenExpired()
      }
    }, 5000);
  }

  handlePaymentDrawer = () => {
    this.setState({isPaymentDrawer: !this.state.isPaymentDrawer})
  }

  getMediaType = () => {
    return this.state.screenSelected && this.state.screenSelected.attributes.media_format[0].toUpperCase() + this.state.screenSelected.attributes.media_format.slice(1) || "-";
  }

  getScreenSelected = () => {
    return this.state.screenSelected && this.state.screenSelected.attributes.screen_name || "Select Screen";
  }

  handlePostCreation = () => {
    let token = getToken();
    token = token ? token : ''

    let bodyData = new FormData();
    bodyData.append("[screen_id]", this.state.screenSelected.id);

    if(this.state.fileName.name === this.state.file?.name){
      bodyData.append("[file]", this.state.file);
      bodyData.append("[recent]", 'false');
    } else {
      bodyData.append("[screen_file_id]", this.state.fileName.screen_file_id);
      bodyData.append("[recent]", 'true');
    }
    bodyData.append("[start_date]", `${moment(this.state.startDate).format("DD-MM-YYYY")}`);
    bodyData.append("[end_date]", `${moment(this.state.endDate).format("DD-MM-YYYY")}`);

    let requestMesg = GenericApiCall('', configJSON.postCreationAPiMethod, configJSON.postCreationApiEndPoint, token, bodyData)
    this.postCreationApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg);
  }

  manageFileUpload = (e: any,indexx:number) => {
    console.log("called e ===>", e);
    if(this.state.uploadedFiles.length > 0){
      this.setState({ errMessage: "Only 1 media file can be upload.", showErrMsg: true })
      
      setTimeout(() => {
        this.setState({ showErrMsg: false, errMessage: "" });
      }, 3000);
      return;
    }
    this.handleFileUpload(e, indexx);
  }

  validateImageDimensions = (file: any, handleMediaPixel: any) => {
    const img: any = new Image();
    const objectURL = URL.createObjectURL(file);

    img.src = objectURL;
    img.onload = function() {
          if((img.width && img.width < 50) || (img.height && img.height < 50)) {
            handleMediaPixel(true);
          } else {
            handleMediaPixel(false);
          }
    };
    img.onload(file);
  }

  handleFileUpload = (e: any,indexx:number) => {
    let targetFiles:any = e.target.files;
    let images = Array.from(targetFiles).map((file:any)=>{
      let preview: any = URL.createObjectURL(file);
      let width = 0;
      let height = 0;
      let handleMediaPixel = (value: boolean) => this.setState({ pixelValid: value });

      if(file.type.includes('video')){
        URL.createObjectURL(file)
        const fileURL = URL.createObjectURL(file);
        this.createThumbnail(fileURL);

        const video = document.createElement('video');
        video.src = fileURL;

        video.onloadeddata = () => {
          width = video.videoWidth;
          height = video.videoHeight;
            if((width && width < 50) || (height && height < 50)) {
              handleMediaPixel(true);
            } else {
              handleMediaPixel(false);
            }
        }

        video.onloadeddata(e);

        const reader: any = new FileReader();
        reader.onload = () => {
          preview = reader.result
        };
        reader.readAsDataURL(file);
      } else if (file.type.includes('image')) {
        this.validateImageDimensions(file, handleMediaPixel);
      }
      return {
        name:file.name,
        size:file.size,
        type:file.type,
        preview:preview,
        height: height,
        width: width,
      }
    })
    images.forEach((image,inde)=>{
      let position = indexx + inde;
      let inter = setInterval(()=>{
        let {uploadedFiles,status,progress,failedFiles} = this.state;
        uploadedFiles[position] = image
        if(!progress[position]){
         progress[position] = 0
        }
        if(status[position] === 'fail'){
          progress[position] = 100;
        } else if(status[position] !== 'done'){
          status[position] = 'uploading';
          progress[position] = Math.min(progress[position]+10,100);
        }
        if(progress[position] === 100){
          let format = image.type.split('/')[1]
          if(!image.type.includes(this.state.screenSelected.attributes.media_format)){
            status[position] = 'fail';
            failedFiles.push(1)
            this.handleMediaType();
          } else if(image.size  / (1024 * 1024) > this.fetchMaxSizeMedia(this.state.screenSelected.attributes.media_format)){
            status[position] = 'fail';
            failedFiles.push(1)
            this.handleMediaSize()
          } else if(this.checkMediaFormat(format)) {
            status[position] = 'fail';
            failedFiles.push(1)
            this.handleMediaFormat();
          } else if(this.state.durationValid) {
            status[position] = 'fail';
            failedFiles.push(1);
            this.setState({mediaErr: 'The uploaded media duration should be less than 60 Seconds'});
          } else if(this.state.pixelValid) {
            status[position] = 'fail';
            failedFiles.push(1);
            this.handleMediaPixel();
          } else {
            status[position]='done'
          }
          
          clearInterval(inter);
        }
        this.setState({file: targetFiles[0], progress,status,failedFiles,uploadedFiles, fileName: uploadedFiles[0]})

      },500)
    })
  };

  uploadContinueButtonDisabled = () => {
    return this.state.uploadedFiles.length === 0 || this.state.failedFiles.length > 0 || this.state.progress[0] !== 100
  };

  uploadContinueButtonPreviewDisabled = () => {
    return !this.state?.fileName?.screen_file_id && this.state.failedFiles.length > 0;
  };

  uploadFileDisabled = () => {
    return this.state.uploadedFiles.length > 0 || this.state.failedFiles.length > 0
  };

  handleMediaSize = () => {
    !this.state.mediaErr && this.setState({ mediaErr: `The uploaded media size should be less than ${this.fetchMaxSizeMedia(this.state.screenSelected.attributes.media_format)}MB.` })
  }

  handleMediaType = () => {
    !this.state.mediaErr && this.setState({ mediaErr: `Selected screen accepts only ${this.state.screenSelected.attributes.media_format}.` })
  }

  handleMediaFormat = () => {
    !this.state.mediaErr && this.setState({ mediaErr:"The uploaded media doesn't have supported format." })
  }

  handleMediaPixel = () => {
    console.log("this.state.mediaErr", this.state.mediaErr);
    
    !this.state.mediaErr && this.setState({ 
      mediaErr:"Files with height and width less than 50 pixels should not be allowed to upload." 
    })
  }

  createThumbnail = (videoSrc: any) => {
    const video = document.createElement('video');
    video.src = videoSrc;
    video.onloadeddata = () => {
      const canvas = this.canvasRef.current;
      const context = canvas?.getContext?.('2d');
      video.currentTime = 3; // Capture a frame at 3 seconds
      if(Math.floor(video.duration) > 60) {
        this.setState({
          durationValid: true,
        });
      } else {
        this.setState({
          durationValid: false,
        });
      }
      video.onseeked = () => {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas.toDataURL('image/png');
        this.setState({ thumbnail: dataURL })
        URL.revokeObjectURL(videoSrc); // Free up memory
      };
    };

    video.onloadeddata(videoSrc);
  }

  isScreenUnAvailable = () => {
    return this?.state?.screenSelected?.attributes?.status === "unavailable" || this.getPathDetails() === "/PaymentPreview" ? true : false;
  }

  fetchMaxSizeMedia = (type: string) => {
    switch (type) {
      case "video":
        return 100;
      case "image":
        return 12;
      default:
        return 12;
    }
  };

  checkMediaFormat=(format:string)=>{
    switch(format){
      case'jpeg':
      case'jpeg 2000':
      case'gif':
      case'png':
      case'webp':
      case'bmp':
      case'tiff':
      case'mp4':
      case'mpg':
      case'mkv':
      case'avi':
      case'mov':
      case'ogg':
      case'm3u8':
      case'flv':
      case'webm':
        return false
      default:
        return true
    }
  }
  handleRemove = (index: number,e:any) => {
    this.setState({ file: [], uploadedFiles: [] ,failedFiles: [],progress: [],status: [], mediaErr: "", durationValid: false });
  };
  updateVideoDetails = () => {
    const videoElement = document.getElementById(
      "previewVideo"
    ) as HTMLVideoElement;
    if (videoElement) {
      // videoElement.addEventListener("loadedmetadata", () => {
        const blob = new Blob([videoElement.src], { type: this.state.fileName.type }); // Create a blob from the video source
        const reader = new FileReader();

        reader.onload = (event) => {
          this.setState({
            duration: `${Math.floor(videoElement.duration)} seconds`,
          });
        };

        reader.readAsArrayBuffer(blob); // Read the blob as an ArrayBuffer
      // });
    }
  };
  changeScreen=(value:any)=>{
    let screenSelected = this.state.allScreens.find((screen:any)=>{
      return screen.id === value
    })
    this.getScreensBookings(screenSelected);
    const recentFiles = this.state.recentFilesAll.filter((file: any) => file.attributes?.media_format?.toLowerCase() === screenSelected.attributes?.media_format?.toLowerCase());
    this.setState({ screenSelected, amount: screenSelected.attributes.price, recentFiles: recentFiles.splice(0, 3), startDate: '', endDate: '', showErrMsg: false, errMessage: "" })
  }
  getScreensDetail=()=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, configJSON.getScreensApiEndPoint,token)
    this.getScreensApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  getScreensFiles=()=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, configJSON.getScreenFilesApiEndPoint,token)
    this.getScreenFilesApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  getSavedCardList = () => {
    let token:any = getToken()
    let requestMesg = GenericApiCall(
      configJSON.validationApiContentType, 
      configJSON.validationApiMethodType, 
      configJSON.SavedAccountEndPoint,
      token)
    this.getSavedCardListId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  getScreensBookings=(screenSelected: any)=>{
    let token:any = getToken()
    let requestMesg = GenericApiCall(configJSON.getScreensBookingContentType, configJSON.getScreensBookingMathodType, `${configJSON.getScreenBooking}?screen_id=${screenSelected?.id}`,token)
    
    this.getScreenBooklingsApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  
  navigateBackPaymentScreen = () => {
    let scannedQrValue:any = localStorage.getItem('scannedQrValue');
    const selectedScreen = localStorage.getItem('selectedScreen');
    const selectedActiveScreen = localStorage.getItem('selectedActiveScreen');
    const fromRejectedScreen = localStorage.getItem("from");

    if(this.getPathDetails() === "/PaymentPreview"){
      this.navigateToOtherScreen('Uploadmedia3');
    } else if(scannedQrValue || selectedScreen || selectedActiveScreen) {
      this.navigateToOtherScreen('LocalListingAds');
    } else if(fromRejectedScreen) {
      this.navigateToOtherScreen('LocalListingFiles');
    } else {
      this.navigateToOtherScreen('HomeDashboard');
    }
  }

  handleContinuePayment = () => {
    if(this.getPathDetails() === "/PaymentPreview") {
      this.handlePaymentDrawer()
    } else {
      this.navigateToOtherScreen('Uploadmedia3', this.state)
    }
  }

  handleCancel = () => {
    let scannedQrValue:any = localStorage.getItem('scannedQrValue');
    const selectedScreen = localStorage.getItem('selectedScreen');
    const selectedActiveScreen = localStorage.getItem('selectedActiveScreen');
    const fromRejectedScreen = localStorage.getItem("from");

    if(scannedQrValue || selectedScreen || selectedActiveScreen) {
      this.navigateToOtherScreen('LocalListingAds');
    } else if(fromRejectedScreen) {
      this.navigateToOtherScreen('LocalListingFiles');
    } else {
      this.navigateToOtherScreen('HomeDashboard');
    }
  }

  handleDisableContinue = () => {
    return this.getPathDetails() === "/PaymentPreview" ? false : this.isDisabledProceedUpload()
  }

  handleNavigationLocalStorage = (screenName?:string,params?:any) => {
    if(screenName === "PaymentPreview" || screenName === "Preview"){
      let ImageName = params;
      ImageName.screen = screenName;

      let paymentD = { ...this.state }
      paymentD.anchorEl = null;
      paymentD.anchorEl2 = null;

      if(params.from === "recentFiles"){
        const typeSplit = params.file.attributes.file_name.split('.');

        const target = {
          type: `${params.file.attributes.media_format}/${typeSplit[typeSplit.length - 1]}`,
          preview: params.file.attributes.file,
          name: params.file.attributes.file_name,
          size: params.file.attributes.file_size,
          screen_file_id: params.file.attributes.id,
        }
        
        localStorage.setItem('uploadImage',JSON.stringify(target))
      } else {
        localStorage.setItem('uploadImage',JSON.stringify(ImageName))
      }
      localStorage.setItem('PaymentScreen', JSON.stringify(paymentD))
    }

    if(screenName === "Uploadmedia3"){
      let paymentData = params;
      paymentData.screen = screenName;
      localStorage.setItem('PaymentScreen', JSON.stringify(paymentData))
    }
  }

  navigateToOtherScreen(screenName?:string,params?:any){
    if(screenName){
      const linkMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
      if(screenName === "PaymentScreen"){
        localStorage.removeItem('uploadImage');
      }
      if(screenName === "HomeDashboard" || screenName === "LocalListingAds" || screenName === "LocalListingFiles"){
        localStorage.removeItem('uploadImage');
        localStorage.removeItem('PaymentScreen');
        localStorage.removeItem('scannedQrValue');
        localStorage.removeItem('selectedScreen');
        localStorage.removeItem('selectedActiveScreen');
      }
      if(params){
        this.handleNavigationLocalStorage(screenName, params);
      }

      const raiseMsg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      
      if(this.state.file){
        raiseMsg.addData("file", this.state.file)
      }

      linkMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      linkMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      linkMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMsg)
      this.send(linkMsg);
    }
    else{
      this.props.navigation.goBack();
    }
  }
  async componentDidMount(): Promise<void> {
    let file:any = localStorage.getItem('uploadImage');
    let paymentData:any = localStorage.getItem('PaymentScreen');
    
    this.getSavedCardList();

    var target = { ...this.state };
    
    if(paymentData){
      target = { ...JSON.parse(paymentData), openScannedModal: false }
    }

    if(!target.screenSelected){
      this.getScreensFiles();
      this.getScreensDetail();
    }

    if(file){
      if(JSON.parse(file).screen){
        target.uploadedFiles = [JSON.parse(file)];
      }
      target.fileName = JSON.parse(file);
    } else {
      target.uploadedFiles = [];
      target.fileName = null;
      target.showErrMsg = false;
      target.errMessage = '';
      target.mediaErr = '';
      target.failedFiles = [];
      target.status = [];
      target.progress = [];
    }

    this.setState(target);
  }

  isDisabledProceedUpload(){
    const { startDate, endDate, screenSelected } = this.state
    if(!startDate || !endDate || screenSelected.attributes.status === "unavailable"){
      return true;
    }
    return false;
  }

  getPathDetails(){
    return window.location.pathname;
  }

  tokenExpired=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    this.send(msg);
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };  
  
  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  // Customizable Area End
}
