import React from 'react';
// Customizable Area Start

import {
  BottomNavigation, 
  BottomNavigationAction,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Container,
  CssBaseline,
} from '@material-ui/core'
import { Menu, ArrowBack, ChevronRight, NotificationsNoneOutlined, Close} from '@material-ui/icons';
import {isMobile} from 'react-device-detect'
import HamburgerMenuController from "./HamburgerMenuController.web";

export const configJSON = require("./config");

interface Tabs {
  id: number;
  value: string;
  icon: string;
  label: string;
  path: string;
  selected_icon: string;
}

interface DrawerType {
  pageName:string;
  pagePath: string;
  noArrow?: boolean;
}
interface IBottomTab {
  id: number;
  value: string;
  icon: string;
  label: string;
  path: string;
  selected_icon: string;
}

const bottomTabs: IBottomTab[] = [
    {
      id:0,
      value: 'home',
      icon: require('../assets/home.png'),
      label: 'Home',
      path: 'HomeDashboard',
      selected_icon: require('../assets/home-selected.png'),
    },
    {
      id: 6,
      value: 'manager_home',
      icon: require('../assets/home.png'),
      label: 'Home',
      path: 'AdManager',
      selected_icon: require('../assets/home-selected.png'),
    },
    {
      id:1,
      value: 'screens',
      icon: require('../assets/slides.png'),
      label: 'Screens',
      path: 'LocalListingAds',
      selected_icon: require('../assets/slide-selected.png'),
  
    },
      {
      id:2,
      value: 'search',
      icon: require('../assets/search.png'),
      label: 'Search',
      path: 'ManagerSearch',
      selected_icon: require('../assets/search-selected.png'),
  
    },
    {
      id:3,
      value: 'user',
      icon: require('../assets/user.png'),
      label: 'Users',
      path: 'Settings',
      selected_icon: require('../assets/user-selected.png'),
  
    },
    {
      id:4,
      value: 'files',
      icon: require('../assets/files.png'),
      label: 'Files',
      path: 'LocalListingFiles',
      selected_icon: require('../assets/file-selected.png'),
    },
    {
    id:5,
    value: 'settings',
    icon: require('../assets/settings.png'),
    label: 'Settings',
    path: 'Settings',
    selected_icon: require('../assets/settings-selected.png'),
      } 
];

   
// Customizable Area End


export default class HamburgerMenu extends HamburgerMenuController {

 
  // Customizable Area Start
  // Customizable Area End

  render() {

    const {
      logOutBlockInnerStyle,
      logOutBlockContainorStyle,
      logOutBlock,
    } = this.props;
    
    return (
      // Customizable Area Start
      
      <>
        <Container maxWidth={"md"} style={{padding:'0px'}}>
        {(this.props.fromPage === "home" || this.props.fromPage === "settings") && 
        <>
          <AppBar
            style={{backgroundColor:'transparent', boxShadow:'none'}}
          >
            <Toolbar>
                <Grid container >
                  <Grid  xs item style={{display:'grid',justifyContent:'start'}}>
                    { this.state.selectedButton == 'home' ?
                      <IconButton
                        edge="start"
                        onClick={this.handleDrawerOpen}
                        aria-label="open drawer"
                        data-test-id="drawer"
                      >
                        <Menu />
                      </IconButton>
                      : 
                      <IconButton data-test-id="backArrowBtn" style={{padding:'0px'}} onClick={()=>this.navigateBack()}>
                        <ArrowBack style={{height:'24px',width:'24px',color:'#fff'}}/>
                      </IconButton>
                    }
                  </Grid>
                  <Grid item  xs={9} sm style={{textAlign:'center'}}>
                    {this.state.selectedButton === 'home' && <img src={require('../assets/image_logo.png')} style={{height:'3em', width:'7.6em'}}/>}

                  </Grid>
                  <Grid item xs  style={{display:'grid', justifyContent:'end'}} container>
                    {(this.state.selectedButton === 'home' || this.state.selectedButton === 'manager_home') && <IconButton
                      edge="start"
                      aria-label="open drawer"
                    >
                      <NotificationsNoneOutlined />
                    </IconButton>
                    }
                  </Grid>
                </Grid>
            </Toolbar>
          </AppBar>
          <Drawer
            className="drawerMenu"
            open={this.state.enableField}
            BackdropProps={{style:{backgroundColor:'red'}}}
            anchor="left"
            variant="persistent"
            style={{width:'80%'}}
          >
            <div style={{display:'flex',justifyContent:'space-between',padding:'20px 0px 5px'}}>
              <img src={require('../assets/image_logo.png')} style={{height:'3em',width:'7.6em',marginLeft:'25px'}}/>
              <IconButton 
                data-test-id="drawer_close" 
                onClick={this.handleDrawerClose}
              >
                <Close style={{color:'green'}}  />
              </IconButton>
            </div>
            <List>
              {this.getDrawerList().map((text: DrawerType, index: number) => (
                <ListItem 
                  key={`${text.pageName}${index}item`} 
                  data-test-id="drawer_menu_click" button 
                  style={{paddingRight:'10px',paddingLeft:'32px'}}
                  onClick={()=>this.navigateToPage(text.pagePath,text.pageName)} 
                >
                  <ListItemText primary={text.pageName} /> 
                  {!text.noArrow && <ListItemIcon style={{minWidth:'10px'}}><ChevronRight /></ListItemIcon>}
                </ListItem>
              ))}
            </List>
          
          </Drawer>
        </>
        }
        <div style={{}}>
          <CssBaseline />
          <main style={{height:isMobile ? '100%' : '100vh', overflowY: "hidden", background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
            {this.props.children}
          </main>
        </div>
        {!this.props.hideBottom && <AppBar position="fixed" color="primary" style={webStyle.appBarStyle}>
          <BottomNavigation value={this.state.selectedButton} showLabels className='bottomNav' style={webStyle.bottomNav} >
            {this.filteredTabs(bottomTabs).map((bottomTab: Tabs) => {
              return (
                <BottomNavigationAction
                key={`${bottomTab.value}`}
                  data-test-id="navigation"
                  label={bottomTab.label}
                  icon={<img style={this.state.selectedButton == bottomTab.value  ? webStyle.selectedIcon : webStyle.unSelectedIcon} src={this.state.selectedButton == bottomTab.value ? bottomTab.selected_icon : bottomTab.icon} />}
                  style={this.state.selectedButton == bottomTab.value  ? webStyle.bottomActionActive : webStyle.bottomAction}
                  value={bottomTab.value}
                  onClick={() => { this.navigateToPage(bottomTab.path, bottomTab.value) }}
                />
              )
            })}
          </BottomNavigation>
        </AppBar>}
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  appBarStyle: {
    top: 'auto',
    bottom: 0,
    background: "#000",
  },
  bottomNav: {
    justifyContent:'space-around',
    width:'100%',
    margin: '12px 0px',
    height: '45px',
    background: "#000",
  },
  bottomAction: {
    color: "#94A3B8"
  },
  bottomActionActive: {
    color: "#FFF",
    fontWeight: 'bold',
  },
  selectedIcon: {
    height: '25px', width: '25px', color: "#FFF", filter: "brightness(0) invert(1)"
  },
  unSelectedIcon: {
    height: '25px', width: '25px', color: "#94A3B8"
  },
};
// Customizable Area End
