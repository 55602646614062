import React, { ReactElement, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { 
    
    Paper,
		Typography,
    IconButton,
    MenuItem,
    ListItemIcon,
    ListItemText,
    styled,
    CircularProgress,
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';

import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVert from '@material-ui/icons/MoreVert';
import { MdEdit, MdOutlineCheckCircle, MdOutlineDvr } from "react-icons/md";
import { CgMenuGridO } from "react-icons/cg";
import { BiCloudUpload } from "react-icons/bi";
import { LuMonitorOff } from "react-icons/lu";
import { get } from './table';
import { getStorageData } from "framework/src/Utilities";
import { FaRegCalendarCheck } from 'react-icons/fa';
import DevicesIcon from '@material-ui/icons/Devices';

const styles = {
  paper: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  header: {
    display: 'flex',
    backgroundColor: 'transparent',
    color: '#94A3B8',
    padding: '10px',
    borderBottom: "1px solid #334155",
  },
  headerCell: {
    flex: 1,
    'text-align': 'left',
    fontWeight: 'bold' as const,
    fontSize: '14px',
  },
	headerCellWithTwo: {
    flex: 1.5,
    'text-align': 'left',
    fontWeight: 'bold' as const,
    fontSize: '14px',
  },
	headerFilterCell: {
    flex: 0.5,
    'text-align': 'center',
    fontWeight: 'bold' as const,
    fontSize: '14px',
  },
  row: {
    display: 'flex',
    color: '#94A3B8',
    padding: '10px',
    background: "#01010180",
		borderBottom: "1px solid #334155",
    'align-items': 'center',
    gap: '20px'
  },
  cell: {
    flex: 1,
    'text-align': 'left',
    fontSize: '12px',
    'text-transform': 'capitalize',
  },
	fullCell: {
    flex: 1,
    'text-align': 'center',
    fontSize: '12px',
  },
	cellWithTwo: {
    flex: 1.5,
    'text-align': 'left',
    fontSize: '12px',
  },
	cellMenu: {
    flex: 0.5,
    'text-align': 'right',
  },
  statusAvailable: {
    backgroundColor: '#FEF3C7',
    color: '#D97706',
    padding: '2px 16px',
    'text-align': 'center',
    borderRadius: '50px',
    display: 'inline-block',
  },
  statusUnAvailable: {
    backgroundColor: '#FEE2E2',
    color: '#F00',
    padding: '2px 8px',
    'text-align': 'center',
    borderRadius: '50px',
    display: 'inline-block',
  },
  statusActive: {
    backgroundColor: '#D1FAE5',
    color: '#059669',
    padding: '2px 27px',
    'text-align': 'center',
    borderRadius: '50px',
    display: 'inline-block',
  },
  container: {
    flexGrow: 1,
    paddingBottom: '33px',
    overflowY: 'auto' as const,
  },
	iconColor: { color: "#94A3B8" },
	svg: {
		color: "#94A3B8",
		height: "25px",
		width: "25px",
	},
    tableDataRow: {
      background: "#01010180",
      borderBottom: "1px solid #334155",
    },
    tableHeader: {
      background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    },
    headerStyles: {
      padding: '10px 8px',
      background: 'transparent'
    },
};

interface RowData {
  screenName: string;
  rate: string;
  status: string;
}

interface State {
  rows: RowData[];
}


type headerKeyTypes = {
	title: string;
	key: string;
	dataKey: string;
}
type objectDataType = {[key:string]: any};

type TablePropTypes = {
	headerKeys: headerKeyTypes[];
	tableData: any[];
	showFilter: boolean;
	moreMenuList: {title: string, key: string, icon: ReactElement}[];
	isLoading?: boolean;
	handleMoreItem?: (key: string, data: objectDataType) => void;
	handleHeaderFilter?: (key: string) => void;
  navigation: any;
}

const headerFilterData = [{
  icon: <CgMenuGridO style={{width: '20px'}} />,
  title: 'All',
  key: 'all'
}, {
  icon: <BiCloudUpload style={{width: '20px'}} />,
  title: 'Active',
  key: 'active'
}, {
  icon: <MdOutlineCheckCircle style={{width: '20px'}} />,
  title: 'Available',
  key: 'available'
}, {
  icon: <LuMonitorOff style={{width: '20px'}} />,
  title: 'Unavailable',
  key: 'unavailable'
}]

export default function ScreenTable(props: TablePropTypes) {

    const {headerKeys, tableData, isLoading, handleMoreItem,navigation} = props;
    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
  
    };
    const emptyRows = props.tableData.length;
    const [headerAnchorEl, setHeaderAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userData, setUserData] = React.useState<any>();

    useEffect(() => {
      const getUserData = async () => {
        const userData = await getStorageData("userData", true);
        userData && setUserData(userData)
      }
      getUserData();

    }, [])
		const handleHeaderMenuClick = (event: React.MouseEvent<HTMLElement>) => {
			setHeaderAnchorEl(event.currentTarget);
		};
	
		const handleHeaderClose = () => {
			setHeaderAnchorEl(null);
		};

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currantRowData, setCurrantRowData] = React.useState<objectDataType>({});

    const getChipColor = (value: string) => {
        if(value === 'unavailable') return styles.statusUnAvailable;
        if(value === 'available') return styles.statusAvailable;
        if(value === 'active') return styles.statusActive;
        return {display: 'none'}
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, rowData: objectDataType) => {
        setAnchorEl(event.currentTarget);
        setCurrantRowData(rowData);
    };
    
		const handleClose = () => {
        setAnchorEl(null);
        setCurrantRowData({});
    };

    const navigateToPage = (path: string, params: any) => {
      props.navigation.navigate(path, params)
    }
    
    const handleManagerMoreMenu = (key: string) => {
      switch (key) {
        case 'Edit_screen':
          navigateToPage(`EditScreen`, {screenId: currantRowData.id})
          break;
        case 'View_screen':
          navigateToPage(`ManagerScreenDetail`, {screenId: currantRowData.id})
          break;
        case 'Ad_details':
          if(currantRowData?.attributes?.files && currantRowData?.attributes?.files.length) navigateToPage(`ManagerAdDetail`, {adId: currantRowData?.attributes?.files[0]?.id})
          else handleClose();
          break;
        case 'View_ad':
          if(currantRowData?.attributes?.files && currantRowData?.attributes?.files.length) navigateToPage(`ViewAd`, {adId: currantRowData?.attributes?.files[0]?.id})
          break;
        
        default:
          break;
      }
    }
    
		const handleMenuItemClick = (key: string) => {
      if(userData && userData.roleName === 'screen_manager') {
        handleManagerMoreMenu(key);
      } else {
        handleMoreItem && handleMoreItem(key, currantRowData);
        handleClose();
      }
		}
    
		const handleHeaderFilter = (key: string) => {
        props.handleHeaderFilter && props.handleHeaderFilter(key);
				handleHeaderClose();
		}

    const getMoreMenuData = (): any[] => {
      if(userData && userData.roleName === 'screen_manager' && location.pathname !== "/ManagerSearch") {
        return [
          {
            title: "Edit Screen",
            key: 'Edit_screen',
            icon: <MdEdit style={{width: '20px'}} />,
          },
          {
            title: "View Screen",
            key: 'View_screen',
            icon: <FaRegCalendarCheck style={{width: '20px'}} />,
          },
          {
            title: "Ad Details",
            key: 'Ad_details',
            icon: <MdOutlineDvr style={{width: '20px'}} />,
          },
          {
            title: "View Ad",
            key: 'View_ad',
            icon: <DevicesIcon style={{width: '20px'}} />,
          }
        ].filter((item) => {
          if(item.key === 'View_ad' && location.pathname !== "/ManagerSearch") return item;
          else return item;
        })
      } else {
        return props.moreMenuList;
      }
    }
    
    

    return (
      <Paper style={{...styles.paper, display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div style={{position: 'sticky', top: 0, zIndex: 1 , display:"block"}}>
        <div style={styles.header}>
          <div style={styles.headerCellWithTwo}>Screen Name</div>
          <div style={styles.headerCell}>Rate</div>
          <div style={styles.headerCell}>Status</div>
					<div style={styles.headerFilterCell}>
						{props.showFilter && (
							<>
								<IconButton
									aria-haspopup="true"
									style={{padding: 0}}
									onClick={(event) => handleHeaderMenuClick(event)}
								>
									<FilterListIcon style={styles.iconColor} />
									</IconButton>
								<StyledMenu
									id="customized-menu"
									anchorEl={headerAnchorEl}
									keepMounted
									open={Boolean(headerAnchorEl)}
									onClose={handleHeaderClose}
								>
									{headerFilterData.map((item, index) => {
										return (  
											<StyledMenuItem 
												key={`${item.key}-${index}`} 
												onClick={() => handleHeaderFilter(item.key)}
											>
												<ListItemIcon style={{minWidth: "30px"}}>
													{item.icon}
												</ListItemIcon>
												<StyledListItemText primary={item.title} />
											</StyledMenuItem>
										)
									})}
								</StyledMenu>
							</>
						)}
					</div>
        </div>
        </div>
        <div style={styles.container}>
          {tableData.map((row: any, index: number) => (
            <div style={styles.row} key={index}>
              <div style={styles.cellWithTwo}>{get(row, 'attributes.screen_name')?.length > 12 ? `${get(row, 'attributes.screen_name').slice(0, 12)}...` : get(row, 'attributes.screen_name')}</div>
              <div style={styles.cell}>{get(row, 'attributes.price')}</div>
              <div style={styles.cell}>
                <Typography style={{...getChipColor(get(row, 'attributes.status')), textTransform: 'uppercase', fontSize: '12px', fontWeight: 700}}>
                  {get(row, 'attributes.status')}
                </Typography>
              </div>
							<div style={styles.cellMenu}>
								<IconButton
									aria-haspopup="true"
									style={{padding: 0}}
									onClick={(event) => handleMenuClick(event, row)}
								>
									<MoreVert style={styles.iconColor} />
								</IconButton>
								<StyledMenu
									id="customized-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									{getMoreMenuData().map((menu) => (currantRowData?.attributes?.status === 'unavailable' && menu.key === 'upload') ? null : (
										<StyledMenuItem key={menu.key} onClick={() => handleMenuItemClick(menu.key)}>
											<ListItemIcon style={{minWidth: "30px"}}>
												{menu.icon}
											</ListItemIcon>
                      <StyledListItemText primary={menu.title} />
										</StyledMenuItem>
									))}
								</StyledMenu>
							</div>
            </div>
          ))}
					{!isLoading && !emptyRows && (
						<div style={{ ...styles.row, height: (53) * 1 }} >
							<div style={styles.fullCell}>
								No data
							</div>
						</div>
					)}
					{isLoading && (
						<div style={{ ...styles.row, height: (53) * 2, backgroundColor: 'transparent', border: 0 }}>
							<div style={styles.fullCell}>
								<CircularProgress style={styles.iconColor} />
							</div>
						</div>
					)}
        </div>
      </Paper>
    );
  
}

const StyledMenu = withStyles({
  paper: {
    background: "#000",
    color: "#94A3B8",
    borderRadius: "8px"
  },
  list: {
		padding: 0
	},
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = styled(MenuItem)({
  color: '#94A3B8',
  minWidth: '180px',
  '& svg': {
    color: '#94A3B8',
    height: '25px',
    width: '25px',
  },
});

const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '14px',
  },
});