import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  current: number;
  fade: boolean;
  screenAssetsData: any;
  assetType: string;
  qrCode: string;
  muted: boolean;
  loader: boolean;
  error: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfonplatformscreencreation0Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  screenAssetsApiCallId: string = "";
  videoRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      current: 0,
      fade: true,
      screenAssetsData: [],
      assetType: "",
      qrCode: "",
      muted: true,
      loader: true,
      error: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.videoRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.screenAssetsApiCallId){
          this.setState({ loader: false });
          if (!responseJson.errors) {
            this.handleCurrentScreenAssets(responseJson);
          } else {
            if(responseJson.errors){
              this.setState({ error: responseJson.errors, qrCode: "", assetType: "", screenAssetsData: [] });
            } else {
              this.setState({ error: "Something went wrong!", qrCode: "", assetType: "", screenAssetsData: [] });
            }
          }
        }
      }
    }
    // Customizable Area End
  }


  handleCurrentScreenAssets = (responseJson: any) => {
    if(responseJson.data.qr_code){
      this.setState({ qrCode: responseJson.data.qr_code, error: "" })
    } else if(responseJson.data.assets.length) {
      const assets = responseJson.data.assets.map((item: any) => item.url);
      this.setState({ screenAssetsData: assets, assetType: responseJson.data.assets[0].media_type, error: "" });
    }
  }

  async componentDidMount(): Promise<void> {
    this.getScreenAssets();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.assetType.includes("image") && this.state.screenAssetsData.length > 1){
      setInterval(this.nextImage, 5000);
    }

    setTimeout(() => {
      this.setState({ muted: false })
      const videoElement = this.videoRef.current;
      if (videoElement) {
        videoElement.muted = false; // Ensure the video element is unmuted
      }
    }, 1000); 
  };

  handleVideoEnd = () => {
    this.setState(prevState => ({
      current: (prevState.current + 1) == prevState.screenAssetsData.length ? 0 : prevState.current + 1,
    }));
  }

  nextImage = () => {
    this.setState({ fade: false }); // Trigger the fade out
    setTimeout(() => {
      this.setState(prevState => ({
        current: prevState.current > 0 ? 0 : prevState.current + 1,
        fade: true // Trigger the fade in
      }));
    }, 300); // Duration of the fade-out effect
  };

  getScreenAssets() {
    this.setState({ loader: true })
    const screenId = this.props.navigation.getParam('uniqueid');
    const headers = {
      "Content-Type": configJSON.screenAssetsApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.screenAssetsApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.screenAssetsAPiEndPoint + "3c92b59c45ea366a3f71342"
      // configJSON.screenAssetsAPiEndPoint + "7fbd54e706a273a3253b341"
      configJSON.screenAssetsAPiEndPoint + screenId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.screenAssetsApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  // web events

  // Customizable Area Start
  // Customizable Area End
}
